<template>
  <website-config />
</template>

<script>
  import WebsiteConfig from '@/components/WebsiteConfig.vue'

  export default {
    name: 'GoogleConfirmation',
    components: {
      WebsiteConfig,
    },
  }
</script>
